import React, {useEffect, useMemo, useRef} from "react";
import videojs from "video.js";
import qualitySelector from 'videojs-hls-quality-selector';
import 'videojs-contrib-quality-levels';
import 'video.js/dist/video-js.css';

import MediaSession from '@mebtte/react-media-session';
import {useTheme} from "@mui/material/styles";
import {useSettingsService} from "../../services/settings";
import {usePrevious} from "../../hooks/previous";

const defaultOptions = {
    preload: 'cover',
    autoplay: true,
    controls: true,
    fluid: true,
    responsive: true,
    aspectRatio: '16:9',
    notSupportedMessage: 'Es ist ein Fehler beim abspielen aufgetreten.',
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
};


const defaultVideoPlayerProps = {
    data: {},
    onReady: () => {},
    user: {},
    type: 'video',
}

const VideoPlayer = (props = defaultVideoPlayerProps) => {
    const theme = useTheme();
    const settingsService = useSettingsService();

    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    const {data, onReady, user, type} = props;
    const prevData = usePrevious(data);

    const {volume} = settingsService.settings;

    useEffect(() => {
        if (videojs.getPlugin('hlsQualitySelector') === undefined) {
            videojs.registerPlugin('hlsQualitySelector', qualitySelector);
        }
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            const player = playerRef.current = videojs(videoElement, defaultOptions, () => {
                onReady && onReady(player);
            });
        }
    }, [videoRef]);

    useEffect(() => {
        const player = playerRef.current;
        if (data?.id && user?.token) {
            videojs.Vhs.xhr.beforeRequest = (xhrOptions) => {
                xhrOptions.headers = {
                    authorization: 'Bearer ' + user?.token,
                };
            };

            if (prevData?.id !== data?.id) {
                loadNewVideo();
            }

            if (player) {
                if (typeof volume != 'undefined' && volume != null) {
                    player.volume(volume);
                }
            }
        }
    }, [data, user, volume, type])

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const play = () => {
        const player = playerRef.current;
        player.play();
    }

    const pause = () => {
        const player = playerRef.current;
        player.pause();
    }

    const loadNewVideo = () => {
        const player = playerRef.current;
        if (player) {
            player.autoplay(true);
            if (type === 'video') {
                player.src([{
                    src: 'https://lehmoflix.de/hls/' + data.id + '/master.m3u8?token=' + user?.token,
                }]);
                player.poster(data.background_image);
            }
            if (type === 'channel') {
                player.src([{
                    src: data.channel_url,
                }]);
                player.poster('https://lehmoflix.de/media/channel/' + data.id + '/550x310.png');
            }
            if (typeof player.hlsQualitySelector !== "object") {
                player.hlsQualitySelector();
            }
        }
    }

    const getGlobalTitle = () => {
        return data.title ?? data.name ?? '';
    }

    const getGlobalImage = () => {
        if (type === 'video') {
            return data?.cover_image ?? data?.background_image
        }
        if (type === 'channel') {
            return 'https://lehmoflix.de/media/channel/' + data?.id +'/550x310.png';
        }
    };

    return useMemo(() => {
        return (
            <div className={'lehmoflix-player ' + theme.palette.mode}>
                {data && data.id && (
                    <MediaSession
                        title={getGlobalTitle()}
                        artist={'LEHMOFLIX'}
                        artwork={[{
                            src: getGlobalImage(),
                            sizes: '512x512',
                        }]}
                        onPlay={play}
                        onPause={pause}
                    />
                )}
                <video
                    ref={videoRef}
                    className={'video-js vjs-fluid vjs-big-play-centered'}
                />
            </div>
        )
    }, [theme, data, videoRef])

};

export default VideoPlayer;
