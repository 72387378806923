import React, {createContext, useContext, useRef} from "react";
import {useAuthenticationApi} from "./authentication";
import ApiHelper from "../api/helper";

const LehmoflixApi = require('../api');

export const useSearchService = () => {
    const authService = useAuthenticationApi();
    const api = new LehmoflixApi.SearchApi(authService.getApiClient());

    const lastQuery = useRef(null);

    const search = (query, options = {limit: 25, offset: 0}) => {
        lastQuery.current = query;
        return new Promise((resolve, reject) => {
            api.searchForAll(query, options,(error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        });
    };

    const searchFast = (query) => {
        return new Promise((resolve, reject) => {
            api.searchFast(query, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        });
    };

    return useContext(createContext({lastQuery: lastQuery.current, search, searchFast}));
};
