import React from "react";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const PlayerItem = ({player}) => {

    const renderContent = () => {
        return (
            <Box sx={{width: 200, mr: 2, height: '100%', mb: 1}}>
                <Card elevation={0}>
                    <CardMedia component={'img'} src={player?.image ?? '/user_placeholder.jpg'} sx={{width: 200, height: 150}} />
                    <CardContent sx={{px: 1}}>
                        {player?.movie_role ? (
                            <>
                                <Typography variant={'subtitle2'} sx={{p: 0, m: 0}}>{player.name}</Typography>
                                <Typography variant={'caption'} sx={{p: 0, m: 0}}>{player?.movie_role}</Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant={'subtitle1'} sx={{p: 0, m: 0}}>{player.name}</Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box>
        )
    };

    if (player.user) {
        return (
            <Link to={'/user/' + player.user.id} style={{textDecoration: 'none'}}>
                {renderContent()}
            </Link>
        )
    } else {
        return renderContent();
    }
};

export default PlayerItem;
