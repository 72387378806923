import React, {createContext, useContext} from "react";
import ApiHelper from "../api/helper";
import {useAuthenticationApi} from "./authentication";

const LehmoflixApi = require('../api');

export const useMovieService = () => {

    const authService = useAuthenticationApi();
    const api = new LehmoflixApi.MovieApi(ApiHelper.getApiClient(authService.user));

    const list = (params) => {
        return new Promise((resolve, reject) => {
            api.list(params, ((error, data, response) => {
                if (!error) {
                    resolve({data: response.body, inputParams: params});
                } else {
                    reject();
                }
            }));
        });
    }

    const getVideoById = (id) => {
        return new Promise((resolve, reject) => {
           api.getVideo(id, (error, data, response) => {
               if (!error) {
                   resolve(response.body);
               } else {
                   reject();
               }
           })
        });
    }

    const getCommentsByVideoId = (id) => {
        return new Promise((resolve, reject) => {
            api.getVideoComments(id, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        })
    };

    const postComment = (id, comment) => {
        return new Promise((resolve, reject) => {
            api.postComment({body: comment}, id, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        })
    };

    const getPlayerRolesByVideoId = (id) => {
        return new Promise((resolve, reject) => {
            api.getVideoPlayers(id, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        })
    }

    const trackPosition = (id, position) => {
        return new Promise((resolve, reject) => {
            api.setViewStatus({seconds: position}, id, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            })
        });
    };

    const getRatings = (movieId) => {
        return new Promise((resolve, reject) => {
            api.getRatings(movieId, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        });
    };

    const setRating = (movieId, stars) => {
        return new Promise((resolve, reject) => {
            const rating = new LehmoflixApi.NewRatingBody();
            rating.stars = stars;
            api.postRating(rating, movieId, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        });
    };

    return useContext(createContext({
        list,
        getVideoById,
        getCommentsByVideoId,
        postComment,
        getPlayerRolesByVideoId,
        trackPosition,
        getRatings,
        setRating
    }));
};
