import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    Box, Button, Card, CardContent, CardMedia, Chip,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    FormControl, FormHelperText,
    InputLabel,
    OutlinedInput, Snackbar,
    Switch,
    Typography
} from "@mui/material";
import {useAuthenticationApi} from "../../services/authentication";
import {FileUpload} from "@mui/icons-material";
import {useUserService} from "../../services/user";
import {SettingsContext, useSettingsService} from "../../services/settings";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

const SettingsPage = () => {
    const authService = useAuthenticationApi();
    const userService = useUserService();
    const settingsService = useSettingsService();

    const user = authService.user;

    const fileUploadRef = useRef(null);

    const [tempUser, setTempUser] = useState(user);
    const [open, setOpen] = React.useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccessMessageClose = () => {
        setSuccessMessage(null);
    };

    const onFileUploadClick = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const updateLocalState = (user) => {
        authService.saveUser(user);
        window.location.reload();
    };

    const onChangeValue = (key, value) => {
        let userCopy = {...tempUser};
        userCopy[key] = value;
        setTempUser(userCopy);
    };

    const onVerifyPassword = (event) => {
        let userCopy = {...tempUser};
        userCopy.confirmation = {
            username: user?.username,
            password: event.target.value,
        };
        setTempUser(userCopy);
    }

    const saveChanges = () => {
        if (tempUser?.confirmation?.password?.length > 0) {
            userService.updateMe(tempUser).then(updateLocalState);
        } else {
        }
    };

    const saveLocalSetting = (key, value) => {
        if (value === 'on') {
            value = !settingsService.settings[key];
        }
        console.log(key, value);
        settingsService.saveSetting(key, value);
    };

    const resetViewStats = () => {
        const onSuccess = (data) => {
            if (data.success) {
                setSuccessMessage('Verlauf erfolgreich zurückgesetzt');
            }
        };
        userService.resetViewStats().then(onSuccess);
    };

    useEffect(() => {
        const upload = fileUploadRef.current;
        if (upload) {
            upload.onchange = event => {
                const image = event.target.files[0];
                if (image) {
                    userService.uploadProfileImage(image).then(updateLocalState);
                }
            };
        }
    }, [fileUploadRef.current]);

    useEffect(() => {
        document.title = 'LEHMOFLIX | Einstellungen';
    }, [authService])


    const renderSettingsPage = (settings) => {
        return (
            <Container component={'div'} maxWidth={'lg'} sx={{p: 4}}>
                <Typography variant={'h4'} sx={{mb: 2}}>Einstellungen</Typography>
                <Box sx={{display: 'flex', mb: 2}}>
                    <Card variant={'outlined'} sx={{width: '100%'}}>
                        <Typography variant={'h6'} sx={{px: 2, pt: 1}}>Dein Profil</Typography>
                        <CardContent sx={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            <CardMedia component={'img'} src={user?.image ?? '/user_placeholder.jpg'}
                                       sx={{width: '33%', borderRadius: 1, mr: 2, mb: 2}}/>
                            <Box sx={{
                                flex: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                mb: 2,
                            }}>
                                <Box sx={{flex: 1}}>
                                    <FormControl variant="outlined" sx={{width: '100%', mb: 2}}>
                                        <InputLabel htmlFor="outlined-userid">LEHMOFLIX-ID</InputLabel>
                                        <OutlinedInput
                                            fullWidth={true}
                                            id="outlined-userid"
                                            type={'text'}
                                            value={tempUser?.id}
                                            label="LEHMOFLIX-ID"
                                            disabled={true}
                                        />
                                    </FormControl>
                                    <FormControl variant="outlined" sx={{width: '100%', mb: 2}}>
                                        <InputLabel htmlFor="outlined-username">Nutzername</InputLabel>
                                        <OutlinedInput
                                            fullWidth={true}
                                            id="outlined-username"
                                            type={'text'}
                                            value={tempUser?.username}
                                            label="Nutzername"
                                            onChange={event => onChangeValue('username', event.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl variant="outlined" sx={{width: '100%', mb: 2}}>
                                        <InputLabel htmlFor="outlined-email">E-Mail</InputLabel>
                                        <OutlinedInput
                                            id="outlined-email"
                                            type={'text'}
                                            value={tempUser?.email}
                                            label="E-Mail"
                                            onChange={event => onChangeValue('email', event.target.value)}
                                        />
                                    </FormControl>
                                    <Box sx={{width: '100%', mb: 2}}>
                                        <Typography variant={'body1'} sx={{mb: 1}}>Du bist in den Gruppen:</Typography>
                                        <Box sx={{display: 'flex', flexWrap: 'wrap',}}>
                                            {user.view_groups.map((group, index) => {
                                                return (
                                                    <Chip key={'own-group-' + index} label={group.name}
                                                          sx={{mr: 0.5, mb: 0.5}}
                                                          color={'default'} variant={'filled'}/>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Button variant="outlined" onClick={onFileUploadClick} startIcon={<FileUpload/>}>
                                        Bild ändern
                                    </Button>
                                    <input ref={fileUploadRef} accept="image/png, image/gif, image/jpeg" type={'file'}
                                           style={{display: 'none'}}/>
                                    <Button onClick={handleClickOpen} variant={'outlined'}
                                            color={'success'}>Speichern</Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box>
                    <Card variant={'outlined'}>
                        <Typography variant={'h6'} sx={{px: 2, pt: 1}}>Allgemein</Typography>
                        <CardContent>
                            <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}>
                                <Box sx={{flex: 1}}>
                                    <Typography variant={'inherit'} fontWeight={'bold'}>DarkMode</Typography>
                                    <FormHelperText variant={'standard'}>Wechsel zwischen hellem und dunklem
                                        LEHMOFLIX-Design</FormHelperText>
                                </Box>
                                <Switch size={'small'} checked={settings.darkMode}
                                        onChange={(event) => saveLocalSetting('darkMode', event.target.value)}/>
                            </FormControl>
                            <Divider/>
                            <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2}}>
                                <Box sx={{flex: 1}}>
                                    <Typography variant={'inherit'} fontWeight={'bold'}>Player-Lautstärke
                                        speichern</Typography>
                                    <FormHelperText variant={'standard'}>Speichert die Player Lautstärke in deinem
                                        Browser</FormHelperText>
                                </Box>
                                <Switch size={'small'} checked={settings.saveVolume}
                                        onChange={(event) => saveLocalSetting('saveVolume', event.target.value)}/>
                            </FormControl>
                            <Divider/>
                            <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2}}>
                                <Box sx={{flex: 1}}>
                                    <Typography variant={'inherit'} fontWeight={'bold'}>Benachrichtigungen</Typography>
                                    <FormHelperText variant={'standard'}>Schalte die Push-Benachrichtigungen ein oder
                                        aus</FormHelperText>
                                </Box>
                                <Switch size={'small'} checked={settings.notifications}
                                        onChange={(event) => saveLocalSetting('notifications', event.target.value)}/>
                            </FormControl>
                            <Divider/>
                            <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2}}>
                                <Box sx={{flex: 1}}>
                                    <Typography variant={'inherit'} fontWeight={'bold'}>Verlauf
                                        zurücksetzen</Typography>
                                    <FormHelperText variant={'standard'}>Setze deinen Aufruf-Verlauf
                                        zurück</FormHelperText>
                                </Box>
                                <Button variant={'outlined'} onClick={resetViewStats}>Zurücksetzen</Button>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{py: 0, pt: 1}}>Änderungen speichern</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{py: 1}}>
                            Um deine Änderungen zu speichern, bestätige diese mit deinem Kennwort.
                        </DialogContentText>
                        <OutlinedInput
                            autoFocus
                            id="password"
                            placeholder={'Passwort'}
                            fullWidth
                            type={'password'}
                            onChange={onVerifyPassword}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'outlined'} color={'error'} onClick={handleClose}>Abbrechen</Button>
                        <Button variant={'outlined'} color={'success'} onClick={saveChanges}>Änderungen
                            übernehmen</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={Boolean(successMessage)} autoHideDuration={6000} onClose={handleSuccessMessageClose}>
                    <Alert onClose={handleSuccessMessageClose} severity="success" sx={{width: '100%'}}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Container>
        )
    }

    return (
        <SettingsContext.Consumer>
            {({settings}) => renderSettingsPage(settings)}
        </SettingsContext.Consumer>
    )
};

export default SettingsPage;
