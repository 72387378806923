import React, {useMemo} from "react";
import {Box, Button, Chip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";


const VideoListItem = ({item, searchVersion = false,}) => {
    const navigate = useNavigate();

    const openVideo = (event) => {
        event.preventDefault();
        navigate('/movie/' + item.id);
    };

    const getFormattedDuration = () => {
        let minute = Math.floor(item.duration / 60);
        let rest_seconds = item.duration % 60;
        minute = parseInt(minute);
        rest_seconds = parseInt(rest_seconds);
        minute = minute < 10 ? '0' + minute : minute;
        rest_seconds = rest_seconds < 10 ? '0' + rest_seconds : rest_seconds;

        return minute + ':' + rest_seconds;
    };

    return useMemo(() => {
        return (
            <Button component={'a'} href={'/movie/' + item.id} onClick={openVideo} sx={{
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                p: 0,
                my: 0.5,
                maxHeight: searchVersion ? 150 : 100,
                overflow: 'hidden',
                borderRadius: 0,
            }}>
                <div style={{
                    background: 'url(\'' + item.background_image + '\') center no-repeat',
                    backgroundSize: 'cover',
                    flex: 1,
                    minWidth: searchVersion ? '33%' : '40%',
                    minHeight: searchVersion ? 150 : 100,
                    position: 'relative',
                }}>
                    <Typography
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            px: 0.5,
                            bgcolor: 'background.duration',
                            color: '#ccc',
                        }}
                        variant={'caption'}>{getFormattedDuration()}</Typography>
                </div>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                    flex: 2,
                    px: 2,
                    py: 1,

                }}>
                    <Typography variant={'h6'} sx={{
                        fontSize: 15,
                        textAlign: 'start',
                        color: 'text.primary',
                        textTransform: 'none',
                        lineHeight: 1.25,
                    }}>{item.title}</Typography>
                    <Typography variant={'subtitle1'} sx={{
                        fontSize: 12,
                        color: 'text.secondary',
                        textTransform: 'none',
                        textAlign: 'start',
                    }}>{item.stream_count + ' Aufruf(e) • ' + moment(item.release_date).format('DD.MM.YYYY')}</Typography>
                    {searchVersion && typeof item?.groups !== "undefined" && item?.groups.length > 0 && (
                        <Box sx={{my: 0.5}}>
                            {item?.groups?.map((group, index) => {
                                return (
                                    <Chip key={'group-chip-' + group.id + '-' + index} color={'default'}
                                          variant={'outlined'} size={'small'} label={group.name}
                                          sx={{
                                              mr: 0.5
                                          }}
                                    />
                                )
                            })}
                        </Box>
                    )}
                </Box>
            </Button>
        )
    }, [item])
};

export default VideoListItem;
