import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Rating, Typography} from "@mui/material";
import {useMovieService} from "../../services/movie";
import {usePrevious} from "../../hooks/previous";

const VideoRatingStars = ({videoId}) => {
    const movieService = useMovieService();
    const prevMovie = usePrevious(videoId);

    const [ratingCount, setRatingCount] = useState(null);
    const [stars, setStars] = useState(0);

    const loadNewRating = useCallback((ratingList) => {
        let starSum = 0;
        for (const ratingListKey in ratingList) {
            starSum += ratingList[ratingListKey].stars;
        }
        setStars(starSum / ratingList.length);
        setRatingCount(ratingList.length);
    }, [ratingCount, stars]);

    const onStarChange = (event) => {
        if (event?.target?.value) {
            movieService.setRating(videoId, event.target.value).then(loadNewRating);
        }
    };

    useEffect(() => {
        if (videoId !== prevMovie) {
            movieService.getRatings(videoId).then((response) => {
                loadNewRating(response.list);
            });
        }
    }, [videoId]);

    return useMemo(() => {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <Typography variant={'caption'}>{(ratingCount ?? 0) + ' Bewertung(en)'}</Typography>
                <Box>
                    <Rating value={stars} max={5} onChange={onStarChange} />
                </Box>
            </Box>
        )
    }, [stars])
};

export default VideoRatingStars;
