import React from "react";

import {BrowserRouter} from "react-router-dom";
import LehmoflixRoutes from "./components/Routing/Routes";
import {Box, CssBaseline} from "@mui/material";
import {SettingsProvider} from "./services/settings";
import 'moment/locale/de';

import './App.css';
import {AuthProvider} from "./services/authentication";
import NotificationProvider from "./services/notification";


function App() {
    return (
        <AuthProvider>
            <NotificationProvider>
                <SettingsProvider>
                    <CssBaseline enableColorScheme={true}/>
                    <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
                        <BrowserRouter>
                            <LehmoflixRoutes/>
                        </BrowserRouter>
                    </Box>
                </SettingsProvider>
            </NotificationProvider>
        </AuthProvider>
    );
}

export default App;
