import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {useTvService} from "../../services/tv";
import LoadingCardImage from "../LoadingCardImage";
import {useNavigate} from "react-router-dom";


const TVPage = ({handleLoadStarted, handleLoadEnded}) => {
    const tvService = useTvService();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        const onSuccess = (response) => {
            setChannels(response);
            setLoading(false);
            handleLoadEnded();
        };

        if (channels.length <= 0 && !loading) {
            handleLoadStarted();
            setLoading(true);
            tvService.getChannels().then(onSuccess);
        }
    }, [loading]);


    const openChannel = (channel) => {
        navigate('/channel/' + channel.id);
    };

    return (
        <Box sx={{p: 4}}>
            <Typography variant={'h5'}>Kanäle</Typography>
            <Box sx={{py: 2, display: 'flex'}}>
                {channels.map((channel, index) => (
                    <Card key={'channel-' + channel.id + '-' + index} sx={{maxWidth: 300, mr: 2}} onClick={() => openChannel(channel)}>
                        <LoadingCardImage src={'https://lehmoflix.de/media/channel/' + channel.id + '/550x310.png'} sx={{m: 0}} />
                        <CardContent>
                            <Typography variant={'h6'}>{channel.name}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    )
};

export default TVPage;
