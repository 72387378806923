import React, {useCallback, useState} from "react";
import {useNotificationService} from "../../services/notification";
import {Box, IconButton, MenuItem, Typography} from "@mui/material";
import {Close, MarkChatUnread, RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import moment from "moment";


const NotificationItemList = ({handleMenuClose}) => {
    const navigate = useNavigate();
    const notificationService = useNotificationService();
    const update = useForceUpdate();
    const {notifications} = notificationService;

    const gotoNotification = (notification, listIndex) => {
        handleMenuClose();
        notificationService.markNotificationsAsRead(listIndex);
        navigate('/movie/' + notification.data.videoId);
    };

    const removeNotification = (listIndex) => {
        notificationService.removeNotification(listIndex);
        update();
    };

    const renderNoNotificationInfo = () => {
        return (
            <MenuItem onClick={handleMenuClose} sx={{display: 'flex', flexDirection: 'column', px: 4}}>
                <MarkChatUnread sx={{my: 1, fontSize: 40}}/>
                <Typography variant={'body2'} sx={{my: 1}}>Keine neuen Benachrichtigungen</Typography>
            </MenuItem>
        )
    };

    if (notifications.length > 0) {
        return notifications.map((notification, index) => {
            return (
                <MenuItem key={'notification-' + index}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {notification?.isRead ? (
                            <RadioButtonUnchecked sx={{my: 1}} color={'primary'} />
                        ) : (
                            <RadioButtonChecked sx={{my: 1}} color={'primary'} />
                        )}
                        <Typography variant={'caption'}>{moment().format('DD.MM.YYYY')}</Typography>
                    </Box>
                    <Box sx={{px: 2}} onClick={() => gotoNotification(notification, index)}>
                        <Typography variant={'inherit'} fontWeight={'500'}>{notification?.title}</Typography>
                        <Typography variant={'body2'} sx={{whiteSpace: "pre-wrap"}}>{notification?.body}</Typography>
                    </Box>
                    <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton onClick={() => removeNotification(index)}>
                            <Close />
                        </IconButton>
                    </Box>
                </MenuItem>
            )
        })
    } else {
        return renderNoNotificationInfo();
    }
};

const useForceUpdate = () => {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick((tick) => tick + 1);
    }, []);
    return update;
};

export default NotificationItemList;
