import React, {useMemo, useRef, useState} from "react";
import {CardMedia, Skeleton} from "@mui/material";

const LoadingCardImage = ({src, sx}) => {
    const imageRef = useRef(CardMedia);
    const [imageStyle, setImageStyle] = useState({display: 'none'});

    const handleLoad = () => {
        setImageStyle({display: 'block'});
    };

    return useMemo(() => {
        return (
            <>
                {imageStyle.display === 'none' && (
                    <Skeleton variant={'rectangular'} animation={'wave'} height={sx?.height ?? 169} sx={sx} />
                )}
                <CardMedia ref={imageRef} component={'img'} src={src} onLoad={handleLoad} sx={{...imageStyle, ...sx}} />
            </>
        )
    }, [src, imageStyle])
};

export default LoadingCardImage;
