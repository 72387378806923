import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuthenticationApi} from "../../services/authentication";

export const useRouteGuard = (ComposedComponent) => {
    const authService = useAuthenticationApi();

    return props => {
        if (authService.isAuthenticated()) {
            return <ComposedComponent
                {...props}
                user={authService.user}
            />
        } else {
            return <Navigate to={{pathname: '/login'}}/>
        }
    };
};
