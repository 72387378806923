import React, {Component, createContext, useContext} from "react";
import {createTheme, ThemeProvider} from "@mui/material";

const defaultSettings = {
    darkMode: true,
    saveVolume: true,
    volume: 0.8,
    notifications: true,
};

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#d90808',
        },
        secondary: {
            main: '#b04747',
        },
        background: {
            default: '#e9e9e9',
            drawer: '#ddd',
            btn: '#444444',
            duration: '#212121aa',
        },
        text: {
            primary: '#000',
            secondary: '#313131'
        }
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#d90808',
        },
        secondary: {
            main: '#b04747',
        },
        background: {
            default: '#212121',
            drawer: '#282828',
            btn: '#444444',
            duration: '#212121aa',
        },
        text: {
            primary: '#fff',
            secondary: '#ccc'
        }
    },
});

export class SettingsProvider extends Component {

    state = {
        settings: defaultSettings,
    };

    constructor(props) {
        super(props);

        try {
            const settingsString = localStorage.getItem('settings');
            let loadedSettings = JSON.parse(settingsString);
            if (loadedSettings) {
                for (const defaultSettingsKey in defaultSettings) {
                    if (!(defaultSettingsKey in loadedSettings)) {
                        loadedSettings[defaultSettingsKey] = defaultSettings[defaultSettingsKey];
                    }
                }
                this.state.settings = loadedSettings;
                console.log('settings loaded', loadedSettings, settingsString);
            }
        } catch (e) {}
    }

    getSettings = () => {
        return this.state.settings;
    }

    saveSetting = (key, value) => {
        let tempSettings = {...this.state.settings};
        tempSettings[key] = value;
        this.setState({settings: tempSettings}, () => {
            this._saveSettings();
        });
        return tempSettings;
    }

    _saveSettings = () => {
        localStorage.setItem('settings', JSON.stringify(this.state.settings));
        return this.state.settings;
    };

    render() {
        const {children} = this.props
        const {settings} = this.state
        const {saveSetting, getSettings} = this

        return (
            <SettingsContext.Provider value={{settings, saveSetting, getSettings}}>
                <ThemeProvider theme={this.state.settings?.darkMode ? darkTheme : theme}>
                    {children}
                </ThemeProvider>
            </SettingsContext.Provider>
        )
    }
}

export const SettingsContext = createContext({
    settings: defaultSettings,
    saveSetting: (key, value) => {},
});
SettingsContext.displayName = 'Settings';

export const useSettingsService = () => {
    return useContext(SettingsContext);
};
