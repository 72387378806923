import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {Button, FormControl, IconButton, OutlinedInput} from "@mui/material";
import {AddComment} from "@mui/icons-material";
import {useMovieService} from "../../services/movie";
import {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {useAuthenticationApi} from "../../services/authentication";
import {useNavigate} from "react-router-dom";

export default function CommentsList({videoId}) {
    const navigate = useNavigate();
    const authService = useAuthenticationApi();
    const movieService = useMovieService();

    const user = authService.getUser();

    const [loadedThread, setLoadedThread] = useState(null);
    const [data, setData] = useState([]);

    const [commentValue, setCommentValue] = useState('');

    const onLoadEnd = useCallback((commentData, append = false) => {
        setLoadedThread(videoId);
        setCommentValue('');
        if (append) {
            setData([...data, ...commentData]);
        } else {
            setData(commentData);
        }
    }, [videoId]);

    const loadData = () => {
        if (videoId && loadedThread !== videoId) {
            movieService.getCommentsByVideoId(videoId).then(onLoadEnd);
        }
    };

    const postNewComment = useCallback((event) => {
        event.preventDefault();
        if (videoId) {
            movieService.postComment(videoId, commentValue).then(newComment => onLoadEnd(newComment, true));
        }
    }, [videoId, commentValue]);

    const handleCommentValueChange = (event) => {
        setCommentValue(event.target.value);
    };

    useEffect(loadData, [videoId]);


    const renderItems = () => {

        const gotoAuthor = (event, author) => {
            event.preventDefault();
            navigate('/user/' + author.id);
        };

        if (data.length > 0) {
            return data.map((comment, index) => (
                <div key={'comment-' + index}>
                    <ListItem alignItems={'flex-start'} sx={{px: 0}}>
                        <ListItemAvatar>
                            <IconButton size={'small'} href={'/user/' + comment.comment.author.id} onClick={gotoAuthor.bind(this, comment.comment.author)}>
                                <Avatar alt={comment.comment.author.username} title={comment.comment.author.username} src={comment.comment.author.image} />
                            </IconButton>
                        </ListItemAvatar>
                        <ListItemText
                            primary={comment.comment.author.username}
                            secondary={
                                <React.Fragment>
                                    {comment.comment.body}
                                </React.Fragment>
                            }
                        />
                        <Typography variant={'caption'}>{moment(comment.comment.created_at).format('DD.MM.YYYY HH:mm') + ' Uhr'}</Typography>
                    </ListItem>
                    <Divider/>
                </div>
            ));
        }

        return (
            <Typography variant={'inherit'} sx={{my: 2, textAlign: 'center'}}>Keine Kommentare vorhanden</Typography>
        )
    }

    return useMemo(() => {
        return (
            <List sx={{ width: '100%'}}>
                <Typography variant={'h6'}>Kommentare</Typography>
                <ListItem sx={{px: 0}}>
                    <ListItemAvatar>
                        <Avatar title={user.username} alt="Remy Sharp" src={user.image} />
                    </ListItemAvatar>
                    <form onSubmit={postNewComment} style={{width: '100%'}}>
                        <FormControl sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}>
                            <OutlinedInput
                                size={'small'}
                                color={'primary'}
                                sx={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0
                                }}
                                placeholder={'Kommentar verfassen'}
                                onChange={handleCommentValueChange}
                                value={commentValue}
                                fullWidth={true}
                            />
                            <Button size={'large'} onClick={postNewComment} sx={{
                                bgcolor: 'background.btn',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0
                            }} variant={'text'} color={'inherit'}>
                                <AddComment sx={{color: '#fff'}}/>
                            </Button>
                            <input type={'submit'} style={{display: 'none'}} />
                        </FormControl>
                    </form>
                </ListItem>
                {renderItems()}
            </List>
        );
    }, [user, data, commentValue]);
}
