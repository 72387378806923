import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Favorite, Home, LiveTv, Logout, Search, Settings, VideoCameraFront, WatchLater} from '@mui/icons-material';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {
    Avatar, Button, Container,
    FormControl,
    LinearProgress, OutlinedInput, Typography,
} from "@mui/material";
import {useCallback, useRef} from "react";
import HomePage from "../Home";
import VideoPage from "../Video";
import {useAuthenticationApi} from "../../services/authentication";
import SettingsPage from "../Settings";
import moment from "moment";
import SearchPage from "../Search";
import TVPage from "../TV";
import ChannelDetailPage from "../Channel";
import LehmoflixNotifications from "../Notification";
import {useSettingsService} from "../../services/settings";
import SearchBar from "../Search/bar";
import UserProfilePage from "../User";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        border: 'none',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MainLayout({user}) {
    const theme = useTheme();
    const authService = useAuthenticationApi();
    const settingsService = useSettingsService();

    const navigate = useNavigate();

    const scrollRef = useRef(Box);

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLoadStarted = () => {
        setLoading(true);
    };

    const handleLoadEnded = () => {
        setLoading(false);
    };

    const logout = () => {
        authService.logout();
        navigate('/login');
    };

    return (
        <Box sx={{display: 'flex', flex: 1}} className={theme.palette.mode}>
            <CssBaseline/>
            <AppBar position="fixed" open={open} theme={theme}>
                <LinearProgress
                    className={(!loading ? 'not-loading' : '') + ' ' + theme.palette.mode}
                    variant={loading ? 'query' : 'determinate'}
                    value={loading ? 100 : 0}
                    sx={{position: 'absolute', top: 0, left: 0, width: '100%'}}
                    color={'primary'}/>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Link to={'/'} className={'lehmoflix-logo ' + (theme.palette.mode === 'dark' ? 'red' : '')}>
                            LEHMOFLIX
                        </Link>
                    </Box>
                    <Container maxWidth={'sm'} sx={{
                        flex: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        <SearchBar />
                    </Container>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', flex: 1}}>
                        <LehmoflixNotifications pushNotifications={settingsService.settings.notifications} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} theme={theme} PaperProps={{
                sx: {
                    bgcolor: 'background.drawer',
                    borderRight: 'none',
                    boxShadow: '0 0 10px ' + theme.palette.background.drawer,
                }
            }}>
                <Box sx={{
                    flex: 1,
                    bgcolor: 'background.drawer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                    <Box>
                        <DrawerHeader sx={{bgcolor: 'background.default', justifyContent: 'flex-end'}} theme={theme}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color: 'text.primary'}}/> :
                                    <ChevronLeftIcon sx={{color: 'text.primary'}}/>}
                            </IconButton>
                        </DrawerHeader>
                        <Divider/>
                        <List>
                            <ListItem key={'home'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <Home/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Home'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'tv'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/tv')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <LiveTv/>
                                    </ListItemIcon>
                                    <ListItemText primary={'TV'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'roles'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/roles')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <VideoCameraFront/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Rollen'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'favorites'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/favorites')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <Favorite/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Favoriten'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'watch-later'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/watch-later')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <WatchLater/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Später ansehen'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider/>
                    </Box>
                    <Box>
                        <Divider/>
                        <List>
                            {user?.id && (
                                <ListItem key={'user'} disablePadding sx={{display: 'block'}}>
                                    <ListItemButton
                                        onClick={() => navigate('/user/' + user?.id)}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                color: 'text.primary'
                                            }}
                                        >
                                            <Avatar src={user?.image} variant={'circular'} alt={user.username}
                                                    title={user.username}
                                                    sx={{width: 25, height: 25}}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'Angemeldet als'} secondary={user?.username}
                                                      secondaryTypographyProps={{
                                                          sx: {
                                                              fontSize: 14,
                                                              color: 'text.primary',
                                                              fontWeight: 'bold',
                                                          }
                                                      }}
                                                      primaryTypographyProps={{
                                                          sx: {
                                                              fontSize: 12,
                                                              color: 'text.secondary'
                                                          }
                                                      }} sx={{opacity: open ? 1 : 0}}/>
                                    </ListItemButton>
                                </ListItem>
                            )}
                            <ListItem key={'settings'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={() => navigate('/settings')}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <Settings/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Einstellungen'} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={'logout'} disablePadding sx={{display: 'block'}}>
                                <ListItemButton
                                    onClick={logout}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: 'text.primary'
                                        }}
                                    >
                                        <Logout color={'primary'}/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Abmelden'}
                                                  sx={{opacity: open ? 1 : 0, color: 'primary.main'}}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Drawer>
            <Box component="main" sx={{flex: 1, overflowY: 'scroll', position: 'relative'}} className={'moz-scrollbar'}
                 ref={scrollRef}>
                <DrawerHeader/>
                <Routes >
                    <Route path={'/'} element={<HomePage handleLoadStarted={handleLoadStarted}
                                                         handleLoadEnded={handleLoadEnded}
                                                         scrollRef={scrollRef}/>}/>
                    <Route path={'search/:query'} element={<SearchPage handleLoadStarted={handleLoadStarted}
                                                                       handleLoadEnded={handleLoadEnded}/>}/>
                    <Route path={'search/'} element={<SearchPage handleLoadStarted={handleLoadStarted}
                                                                       handleLoadEnded={handleLoadEnded}/>}/>
                    <Route path={'movie/:videoId'} element={<VideoPage handleLoadStarted={handleLoadStarted}
                                                                       handleLoadEnded={handleLoadEnded}
                                                                       scrollRef={scrollRef}/>}/>
                    <Route path={'tv'}
                           element={<TVPage handleLoadStarted={handleLoadStarted}
                                            handleLoadEnded={handleLoadEnded}/>}/>
                    <Route path={'channel/:groupId'} element={<ChannelDetailPage handleLoadStarted={handleLoadStarted}
                                                                                 handleLoadEnded={handleLoadEnded}/>}/>
                    <Route path={'user/:userId'} element={<UserProfilePage handleLoadStarted={handleLoadStarted}
                                                                                 handleLoadEnded={handleLoadEnded}/>}/>
                    <Route path={'settings'}
                           element={<SettingsPage/>}/>
                </Routes>
            </Box>
        </Box>
    );
}
