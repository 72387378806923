import React from "react";
import { Routes, Route } from "react-router-dom";
import {useRouteGuard} from "./Guard";

//pages
import LoginPage from "../Login"
import MainLayout from "../Main/Layout";

function LehmoflixRoutes() {
    const SecuredHomePage = useRouteGuard(MainLayout);

    return (
        <Routes>
            <Route
                index
                path="*"
                element={<SecuredHomePage />}
            />
            <Route
                path="/login"
                element={<LoginPage />}
            />
        </Routes>
    );
}

export default LehmoflixRoutes;
