import React, {Component, createContext, useContext} from "react";
import {initializeApp} from "firebase/app";
import {getToken as getFirebaseToken, getMessaging, onMessage} from "firebase/messaging";
import {wait} from "@testing-library/user-event/dist/utils";

const FIREBASE_API_KEY = 'AAAAljoKj_o:APA91bHWYPML5-p6rWLE5lruUv8IFQxJkwXFj6Qq35jqgHTX8zuWs1oibBGCz1gEM-IZbilWhyRYYDXROmv5ia_0TMwRdhsptcgaIFECcTPt9-c0x6E_LzCK_Vd3lQbn63QiG1umPCDu';

export const firebaseConfig = {
    apiKey: "AIzaSyAceG1gOxbcBCeZdneBEvqL03mrwHZ-oeM",
    authDomain: "lehmoflix.firebaseapp.com",
    databaseURL: "https://lehmoflix.firebaseio.com",
    projectId: "lehmoflix",
    storageBucket: "lehmoflix.appspot.com",
    messagingSenderId: "645218865146",
    appId: "1:645218865146:web:8515d524e2971909849e43",
    measurementId: "G-206VGGSW9J"
};

export default class NotificationProvider extends Component {

    firebase;
    messaging;

    subscribedGroups = [];
    notifications = [];

    state = {
        deviceToken: null,
    };

    constructor(props) {
        super(props);
        this.firebase = initializeApp(firebaseConfig);
        this.messaging = getMessaging(this.firebase);
        try {
            const localNotifications = JSON.parse(localStorage.getItem('notifications'));
            if (localNotifications) {
                this.notifications = localNotifications;
            }
        } catch (e) {
        }
    }

    getToken = async () => {
        const token = await getFirebaseToken(this.messaging, {
            vapidKey: 'BNUeva-nlA2-k7vhpu07jBKxVqHG4nnnBFr0DyoaCBZLxzy65Eq938c7W_CJ4-A9CzXw0h80Jipyryeb8w7O2Pw'
        });

        if (token) {
            this.setState({deviceToken: token});
            return token;
        }
        return null;
    };

    subscribeUserToGroups = (user, handler, cancelCallback) => {
        const self = this;
        return new Promise(async (resolve) => {
            cancelCallback = () => {
                resolve(false);
            };
            for (const group of user.view_groups) {
                if (!this.subscribedGroups[group.id]) {
                    this.subscribedGroups[group.id] = 'wait';
                    this.subscribeToTopic(group.id, handler).then(removeCallback => {
                        self.subscribedGroups[group.id] = {
                            unsubscribe: removeCallback
                        };
                    });
                    await wait(1000);
                }
            }
            resolve(true);
        });
    };

    unsubscribeToGroups = () => {
        try {
            for (const group of this.subscribedGroups) {
                group.unsubscribe();
            }
            this.subscribedGroups = [];
            return true;
        } catch (e) {
            return false;
        }
    }

    subscribeToTopic = async (topicName, handler = () => {
    }) => {
        if (this.state.deviceToken) {
            return this.registerTopic(topicName, handler, this.state.deviceToken);
        } else {
            const deviceToken = await this.getToken();
            return this.registerTopic(topicName, handler, deviceToken);
        }
    };

    registerTopic(topicName, handler, deviceToken) {
        const onSuccessfullySubscribed = (response) => {
            if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
            }
            return onMessage(
                this.messaging,
                (payload) => {
                    handler(payload);
                }
            );
        };

        const onNotSubscribed = (error) => {
            console.error(`Can't subscribe to ${topicName} topic`, error);
        };

        // Subscribe to the topic
        const topicURL = 'https://iid.googleapis.com/iid/v1/' + deviceToken + '/rel/topics/' + topicName;
        return fetch(topicURL, {
            method: "POST",
            headers: {
                Authorization: `key=${FIREBASE_API_KEY}`,
            },
        }).then(onSuccessfullySubscribed).catch(onNotSubscribed);
    }

    addNotification = (notification) => {
        if (notification) {
            this.notifications.unshift(notification);
            this.saveNotifications();
        }
    }

    markNotificationsAsRead = (listItemIndex) => {
        this.notifications[listItemIndex].isRead = true;
        this.saveNotifications();
    }

    removeNotification = (listItemIndex) => {
        this.notifications.splice(listItemIndex, 1);
        this.saveNotifications();
    }

    saveNotifications = () => {
        localStorage.setItem('notifications', JSON.stringify(this.notifications));
    }

    render() {
        const {children} = this.props;
        const {
            messaging,
            notifications,
            subscribeToTopic,
            subscribeUserToGroups,
            unsubscribeToGroups,
            addNotification,
            markNotificationsAsRead,
            removeNotification
        } = this;
        const {deviceToken} = this.state;

        return (
            <NotificationContext.Provider
                value={{
                    deviceToken,
                    messaging,
                    notifications,
                    subscribeToTopic,
                    subscribeUserToGroups,
                    unsubscribeToGroups,
                    addNotification,
                    markNotificationsAsRead,
                    removeNotification
                }}>
                {children}
            </NotificationContext.Provider>
        )
    }
}

export const NotificationContext = createContext({
    deviceToken: null,
    messaging: null,
    notifications: [],
    subscribeToTopic: async (topic, handler) => {
    },
    subscribeUserToGroups: async (user, handler) => {
    },
    unsubscribeToGroups: () => {
    },
    addNotification: (notification) => {
    },
    markNotificationsAsRead: (listItemIndex) => {
    },
    removeNotification: (listItemIndex) => {
    }
});

export const useNotificationService = () => {
    return useContext(NotificationContext);
}
