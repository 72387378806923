import React, {useEffect, useState} from "react";
import {Box, Container, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useSearchService} from "../../services/search";
import VideoListItem from "../Video/list-item";
import PlayerItem from "../Players/item";

const SearchPage = ({handleLoadStarted, handleLoadEnded}) => {
    const {query} = useParams();
    const searchService = useSearchService();

    const [data, setData] = useState({movies: [], players: []});

    const onSuccess = (response) => {
        setData(response);
        handleLoadEnded();
    };

    useEffect(() => {
        if (query !== undefined && query?.length > 0) {
            handleLoadStarted();
            searchService.search(query).then(onSuccess);
            document.title = 'LEHMOFLIX | Ergebnisse für ' + query;
        } else {
            setData({movies: [], players: []});
            document.title = 'LEHMOFLIX | Suche';
        }
        return () => {
            handleLoadEnded();
        }
    }, [query])

    return (
        <Container maxWidth={'lg'} sx={{p: 2}}>
            {(typeof query === "undefined" || query?.length === 0) ? (
                <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300}}>
                    <Typography variant={'h6'}>Bitte gebe einen Suchbegriff ein</Typography>
                </Box>
            ) : (
                <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                    <Typography variant={'h6'} color={'text.secondary'}>Ergebnisse für:</Typography>
                    <Typography variant={'inherit'} sx={{fontSize: 20, px: 1}} color={'text.secondary'}>{query ?? ''}</Typography>
                </Box>
            )}
            {data.players.length > 0 && (
                <Box sx={{position: 'relative', pt: 1}}>
                    <div className={'scroll-overlay right'}/>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        overflowX: 'scroll',
                        flexWrap: 'no-wrap',
                        py: 0.5,
                        my: 0.5,
                    }} className={'moz-scrollbar'}>
                        {data.players.map((player, index) => (
                            <PlayerItem player={player} key={'search-player-' + player.id + '-' + index}/>
                        ))}
                    </Box>
                </Box>
            )}
            {data.movies.length > 0 && (
                <Box sx={{display: 'flex', flexDirection: 'column', py: 1}}>
                    {data.movies.map((item, index) => {
                        return (
                            <VideoListItem item={item} key={'search-video-' + item.id + '-' + index} searchVersion={true}/>
                        )
                    })}
                </Box>
            )}
        </Container>
    )
};

export default SearchPage;
