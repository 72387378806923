import React, {useCallback, useState} from "react";
import {Box, Chip} from "@mui/material";
import {useAuthenticationApi} from "../../services/authentication";
import styled from "@emotion/styled";

const ListItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));

const GroupFilter = ({filter, center = false}) => {
    const authService = useAuthenticationApi();
    const {user} = authService;

    const [activeFilter, setActiveFilter] = useState(null);

    const onFilterClick = useCallback((group) => {
        if (group) {
            setActiveFilter(group.id);
            filter({group: group.id, offset: 0});
        } else {
            setActiveFilter(null);
            filter({group: null, offset: 0});
        }
    }, [filter, user]);

    return (
        <Box
             sx={{
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: center ? 'center' : 'flex-start',
                 flexWrap: 'wrap',
                 listStyle: 'none',
                 m: 0,
                 py: 1,
             }}>
            <ListItem key={0}>
                <Chip label={'Alle'}
                      variant={null === activeFilter ? 'filled' : 'outlined'}
                      onClick={() => onFilterClick(null)}
                      color={null === activeFilter ? 'primary' : 'default'}
                />
            </ListItem>
            {user?.view_groups?.map((group, index) => (
                <ListItem key={index + 1}>
                    <Chip label={group.name}
                          variant={group.id === activeFilter ? 'filled' : 'outlined'}
                          onClick={() => onFilterClick(group)}
                          color={group.id === activeFilter ? 'primary' : 'default'}
                    />
                </ListItem>
            ))}
        </Box>
    )
}

export default GroupFilter;
