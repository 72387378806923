const LehmoflixApi = require('../api');

export default class ApiHelper {
    static getApiClient(user) {
        let apiClient = new LehmoflixApi.ApiClient();
        apiClient.authentications = {
            Bearer: {
                name: 'authorization',
                type: 'apiKey',
                apiKey: user?.token,
                apiKeyPrefix: 'Bearer',
                in: 'header',
            },
        };
        return apiClient;
    }
    static getRefreshToken() {
        let user = null;
        try {
            user = JSON.parse(localStorage.getItem('user'));
            return user.refresh_token;
        } catch (e) {}
        return null;
    }
}
