import React, {useEffect, useState} from "react";
import {Close, Notifications, NotificationsActive} from "@mui/icons-material";
import {useNotificationService} from "../../services/notification";
import {Badge, Box, Button, IconButton, Menu, Paper, Snackbar, Typography} from "@mui/material";
import {useAuthenticationApi} from "../../services/authentication";
import NotificationItemList from "./list";

const LehmoflixNotifications = ({pushNotifications = true}) => {
    const authService = useAuthenticationApi();
    const notificationService = useNotificationService();

    let cancelRef = () => {};

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [notificationData, setNotificationData] = useState(null);
    const notificationDisplayed = Boolean(notificationData);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationClose = () => {
        setNotificationData(null);
    };

    const handleNotificationClick = () => {

    };

    const getUnreadCount = () => {
        let count = 0;
        for (const notification of notificationService.notifications) {
            if (!notification?.isRead) {
                count++;
            }
        }
        return count;
    };

    useEffect(() => {
        if (pushNotifications) {
            notificationService.subscribeUserToGroups(authService.user, (payload) => {
                notificationService.addNotification({
                    title: payload.notification.title,
                    body: payload.notification.body,
                    data: payload.data,
                    isRead: false,
                });
                setNotificationData(payload.notification);
            }, cancelRef).catch(console.log);

            return () => {
                notificationService.unsubscribeToGroups();
            };
        } else {
            console.log('run desubscription')
            cancelRef();
            notificationService.unsubscribeToGroups();
        }
    }, [pushNotifications, authService.user]);

    return (
        <div>
            <IconButton
                color={'inherit'}
                variant={'text'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuClick}
            >
                <Badge badgeContent={getUnreadCount()} color={'primary'}>
                    <Notifications/>
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center', px: 2, py: 1}}>
                    <NotificationsActive />
                    <Typography sx={{px: 1}} variant={'body1'} fontWeight={'bold'}>Benachrichtigungen</Typography>
                </Box>
                <NotificationItemList handleMenuClose={handleMenuClose} />
            </Menu>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={notificationDisplayed}
                      autoHideDuration={10000} onClose={handleNotificationClose}>
                <Paper onClick={handleNotificationClick} variant={'elevation'}
                       sx={{p: 2, minWidth: 300, display: 'flex', alignItems: 'center',}} elevation={10}>
                    <NotificationsActive color={'secondary'}/>
                    <Box sx={{flex: 1, px: 2}}>
                        <Typography variant={'inherit'} fontWeight={'bold'}>{notificationData?.title}</Typography>
                        <Typography variant={'body2'} sx={{whiteSpace: "pre-wrap"}}>{notificationData?.body}</Typography>
                    </Box>
                    <IconButton onClick={handleNotificationClose}>
                        <Close/>
                    </IconButton>
                </Paper>
            </Snackbar>
        </div>
    )
};

export default LehmoflixNotifications;
