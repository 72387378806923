import React, {createContext, useContext, useRef} from "react";
import {useAuthenticationApi} from "./authentication";

const LehmoflixApi = require('../api');

export const useUserService = () => {
    const authService = useAuthenticationApi();
    const api = new LehmoflixApi.UserApi(authService.getApiClient());

    const me = () => {
        return new Promise((resolve, reject) => {
            api.me((error, data, response) => {
                if (!error) {
                    resolve(response.body);
                } else {
                    reject();
                }
            })
        });
    };

    const updateMe = (user) => {
        return new Promise((resolve, reject) => {
            api.updateMe(user, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        })
    };

    const uploadProfileImage = (image) => {
        const formData = new FormData();
        formData.append('file', image);
        return new Promise((resolve, reject) => {
            api.updateImage(formData, (response) => {
                resolve(response?.json());
            });
        })
    };

    const resetViewStats = () => {
        return new Promise((resolve, reject) => {
            api.resetHistory((error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            })
        })
    };

    const getUserById = (userId) => {
        return new Promise((resolve, reject) => {
            api.getUser(userId, (error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            });
        });
    };

    return useContext(createContext({me, updateMe, uploadProfileImage, resetViewStats, getUserById}))
};
