/**
 * LEHMOFLIX - API
 * This is the offical LEHMOFLIX-API documentation
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Body2 model module.
* @module model/Body2
* @version 1.0.0
*/
export default class Body2 {
    /**
    * Constructs a new <code>Body2</code>.
    * @alias module:model/Body2
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Body2</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Body2} obj Optional instance to populate.
    * @return {module:model/Body2} The populated <code>Body2</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Body2();
                        
            
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('fullName')) {
                obj['fullName'] = ApiClient.convertToType(data['fullName'], 'String');
            }
            if (data.hasOwnProperty('confirmation')) {
                obj['confirmation'] = ApiClient.convertToType(data['confirmation'], Object);
            }
        }
        return obj;
    }

    /**
    * @member {String} username
    */
    'username' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {String} fullName
    */
    'fullName' = undefined;
    /**
    * @member {Object} confirmation
    */
    'confirmation' = undefined;




}
