import React, {useCallback, useEffect, useState} from "react";
import {
    Box, Button,
    FormControl, FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useAuthenticationApi} from "../../services/authentication";
import {useNavigate} from "react-router-dom";
import {useUserService} from "../../services/user";

const LoginPage = () => {
    const authService = useAuthenticationApi();
    const userService = useUserService();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [form, setForm] = useState({username: '', password: ''});

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleFormChange = (type, event) => {
        let formCopy = {...form};
        formCopy[type] = event.target.value;
        setForm(formCopy);
    };

    const handleLogin = useCallback((event) => {
        event.preventDefault();
        setError(false);

        const onError = () => {
            setError(true);
        };

        authService.login(form).catch(onError);
    }, [form]);

    useEffect(() => {
        if (authService.isAuthenticated()) {
            navigate('/');
        }
    }, [authService?.user?.id, navigate]);

    useEffect(() => {
        const user = authService.user;
        if (user?.token && !user?.id) {
            userService.me().then(ownData => authService.saveUser(ownData));
        }
    }, [authService?.user?.token]);

    useEffect(() => {
        document.title = 'LEHMOFLIX | Login';
    }, [navigate])

    return (
        <form onSubmit={handleLogin} style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#212121'
        }}>
            <Paper square={false} sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}
                   variant={'outlined'}>
                <FormControl sx={{alignItems: 'center', my: 1}}>
                    <span className={'lehmoflix-logo big red'}>LEHMOFLIX</span>
                </FormControl>
                <Box sx={{p: 1, display: 'flex', flexDirection: 'column'}}>
                    <FormControl sx={{m: 1, width: '25ch'}} variant="outlined">
                        <InputLabel htmlFor="outlined-username">Nutzername / E-Mail</InputLabel>
                        <OutlinedInput
                            id="outlined-username"
                            type={'text'}
                            error={error}
                            value={form.username}
                            onChange={event => handleFormChange('username', event)}
                            label="Nutzername / E-Mail"
                        />
                        {error && (
                            <FormHelperText error={error}>Nutzername falsch</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{m: 1, width: '25ch'}} variant="outlined">
                        <InputLabel htmlFor="outlined-password">Passwort</InputLabel>
                        <OutlinedInput
                            id="outlined-password"
                            type={showPassword ? 'text' : 'password'}
                            value={form.password}
                            error={error}
                            onChange={event => handleFormChange('password', event)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Passwort anzeigen"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Passwort"
                        />
                        {error && (
                            <FormHelperText error={error}>Passwort falsch</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{display: 'flex', flexDirection: 'row', m: 1}} variant="outlined">
                        <input type={'submit'} style={{display: 'none'}}/>
                        <Button sx={{flex: 1}} onClick={handleLogin} color={'primary'}
                                variant="outlined">Anmelden</Button>
                    </FormControl>
                </Box>
            </Paper>
        </form>
    )
};

export default LoginPage;
