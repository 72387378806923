import React, {useCallback, useRef, useState} from "react";
import {Box, Button, FormControl, MenuItem, OutlinedInput, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useSearchService} from "../../services/search";
import {useTheme} from "@mui/material/styles";

const SearchBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const searchService = useSearchService();
    const searchRef = useRef();

    const anchorEl = useRef(null);
    const [fastItems, setFastItems] = useState([]);

    const handleSearchSubmit = useCallback((event) => {
        event.preventDefault();
        anchorEl.current = null;
        navigate('/search/' + encodeURIComponent(searchRef.current?.value));
    }, [searchRef]);

    const searchFast = (event) => {
        if (event.target.value.length > 0) {
            anchorEl.current = event.currentTarget;
            searchService.searchFast(event.target.value).then(setFastItems);
        } else {
            resetDropDown();
        }
    };

    const searchSuggestion = (query, event) => {
        event.preventDefault();
        searchRef.current.value = query;
        navigate('/search/' + encodeURIComponent(query));
    };

    const resetDropDown = () => {
        anchorEl.current = null;
    };

    return (
        <form style={{width: '100%'}} onSubmit={handleSearchSubmit}>
            <FormControl sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}>
                <OutlinedInput
                    inputRef={searchRef}
                    size={'small'}
                    color={'info'}
                    sx={{
                        flex: 1,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        color: '#fff',
                    }}
                    placeholder={'Suchen'}
                    fullWidth={false}
                    onChange={searchFast}
                    onFocus={searchFast}
                    onBlur={resetDropDown}
                />
                <Button size={'large'} sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    color: '#fff',
                    borderColor: 'background.btn',
                }} variant={'outlined'} onClick={handleSearchSubmit} color={'secondary'}>
                    <Search/>
                </Button>
            </FormControl>
            {anchorEl.current && (
                <Box
                    anchorel={anchorEl.current}
                    open={Boolean(anchorEl.current)}
                    elevation={0}
                    autoFocus={true}
                    variant={'menu'}
                    role={'menu'}
                    sx={{flexDirection: 'column', position: 'relative'}}
                >
                    <Box sx={{
                        width: anchorEl.current?.clientWidth ?? 0,
                        display: anchorEl.current ? 'flex' : 'none',
                        flexDirection: 'column',
                        position: 'absolute',
                        bgcolor: theme.palette.background.default,
                        py: 1,
                        boxShadow: '0 5px 10px 2.5px #181818',
                    }}>
                        {fastItems.map((text, index) => {
                            return (
                                <MenuItem key={'search-' + index} sx={{py: 0.5}} onClick={searchSuggestion.bind(this, text)}>
                                    <Typography variant={'body1'} color={theme.palette.text.primary}>{text}</Typography>
                                </MenuItem>
                            )
                        })}
                    </Box>
                </Box>
            )}
        </form>
    )
}

export default SearchBar;
