import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, CardMedia, Skeleton, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useMovieService} from "../../services/movie";
import VideoPlayer from "../Player";
import {useAuthenticationApi} from "../../services/authentication";
import RecentList from "./recent";
import {useSettingsService} from "../../services/settings";
import moment from "moment";
import CommentsList from "./comments";
import VideoPlayers from "./players";
import VideoRatingStars from "./rating";

const VideoPage = ({handleLoadStarted, handleLoadEnded, scrollRef}) => {
    let {videoId} = useParams();
    const movieService = useMovieService();
    const authService = useAuthenticationApi();
    const settingsService = useSettingsService();

    const user = authService.user;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    const init = () => {
        const onSuccess = (response) => {
            setData(response);
            setLoading(false);
        };

        if (videoId && videoId !== data?.id) {
            scrollRef.current.scrollTo({top: 0, behavior: 'smooth'});
            setLoading(true);
            handleLoadStarted();
            movieService.getVideoById(videoId).then(onSuccess).catch(handleLoadEnded);
        }

        return () => {
            setLoading(false);
        }
    };

    useEffect(init, [videoId]);

    useEffect(() => {
        const player = playerRef.current;
        const onLoadStart = () => {
            handleLoadStarted();
        };
        const onCanPlay = () => {
            setLoading(false);
            handleLoadEnded();
        };
        const saveVolume = () => {
            settingsService.saveSetting('volume', (settingsService.settings.saveVolume ? player.volume() : 0.8));
        };
        const setInitialStartTime = () => {
            if (data?.start_time > 0) {
                player.currentTime(data.start_time);
            }
        };
        const updatePlayerStatusOnline = () => {
            if (!loading && player.currentTime() > 0) {
                movieService.trackPosition(videoId, player.currentTime()).then(console.log);
            }
        };

        if (data?.title) {
            document.title = 'LEHMOFLIX | ' + data.title;
        }
        if (!loading) {
            handleLoadEnded();
        }

        if (player) {
            player.on('loadstart', onLoadStart);
            player.on('canplay', onCanPlay);
            player.on('pause', updatePlayerStatusOnline);
            player.on('abort', updatePlayerStatusOnline);
            player.on('volumechange', saveVolume);
            setInitialStartTime();
        }

        return () => {
            if (player) {
                updatePlayerStatusOnline();
                player.off('loadstart', onLoadStart);
                player.off('canplay', onCanPlay);
                player.off('pause', updatePlayerStatusOnline);
                player.off('abort', updatePlayerStatusOnline);
                player.off('volumechange', saveVolume);
            }
        }
    }, [data, playerRef.current]);

    return useMemo(() => {
        return (
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <Box className={'lehmoflix-player-content'} sx={{flex: 2, pb: 1}}>
                    <VideoPlayer data={data} type={'video'} user={user} onReady={handlePlayerReady}/>
                    <Box sx={{py: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        {!loading && data?.cover_image && (
                            <CardMedia
                                component={'img'}
                                src={data.cover_image}
                                className={'lehmoflix-video-cover'}
                                sx={{borderRadius: 1, mr: '40px', width: '20%', my: 1}}
                            />
                        )}
                        <Box sx={{flex: 1, py: 1, minWidth: 175}}>
                            {(loading || typeof data?.title === 'undefined') ? (
                                <>
                                    <Skeleton variant="rectangular" height={30} width={'50%'} sx={{my: 0.5}}/>
                                    <Skeleton variant="rectangular" height={15} width={'25%'} sx={{my: 1}}/>
                                    <Skeleton variant="rectangular" height={35} width={'66%'} sx={{my: 1}}/>
                                    <Skeleton variant="rectangular" height={75} width={'75%'} sx={{my: 1}}/>
                                </>
                            ) : (
                                <>
                                    <Typography variant={'h5'}>{data?.title}</Typography>
                                    <Typography
                                        variant={'subtitle2'}>{data?.stream_count + ' Aufruf(e) - Premiere am ' + moment(data?.release_date).format('DD.MM.YYYY')}</Typography>
                                    <Box sx={{display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        <Box>
                                            <Button
                                                variant={'contained'}
                                                size={'small'}
                                                sx={{my: 1, mr: 0.5}}
                                                disabled
                                            >
                                                {data?.highest_quality?.name}
                                            </Button>
                                            <Button
                                                variant={'contained'}
                                                size={'small'}
                                                sx={{my: 1, mr: 0.5}}
                                                disabled
                                            >
                                                {data?.min_age}
                                            </Button>
                                            {data?.status === 'premium' && (
                                                <Button disabled variant={'outlined'} sx={{py: 0.5, mr: 1}}>
                                                    <Typography variant={'caption'} fontWeight={'bold'} sx={{width: 'auto'}}
                                                                color={'warning.main'}>Premium</Typography>
                                                </Button>
                                            )}
                                        </Box>
                                        <VideoRatingStars videoId={data.id} />
                                    </Box>
                                    <Typography
                                        className={'lehmoflix-player-description'}
                                        variant={'subtitle1'}
                                        dangerouslySetInnerHTML={{__html: data?.sub_title}}
                                        sx={{color: 'text.secondary', py: 1}}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                    <VideoPlayers videoId={data.id} />
                    <CommentsList videoId={data.id}/>
                </Box>
                <RecentList disabledItem={videoId}/>
            </Box>
        )
    }, [loading, data])
};

export default VideoPage;
