import React, {useMemo} from "react";
import {Card, CardContent, CardMedia, Typography, Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import LoadingCardImage from "../LoadingCardImage";

const VideoItem = ({item, inGrid = false}, ...props) => {
    const navigate = useNavigate();

    const openVideo = (event) => {
        event.preventDefault();
        navigate('/movie/' + item.id);
    };

    const getFormattedDuration = () => {
        let minute = Math.floor(item.duration / 60);
        let rest_seconds = item.duration % 60;
        minute = parseInt(minute);
        rest_seconds = parseInt(rest_seconds);
        minute = minute < 10 ? '0' + minute : minute;
        rest_seconds = rest_seconds < 10 ? '0' + rest_seconds : rest_seconds;

        return minute + ':' + rest_seconds;
    };

    const renderBackgroundCard = () => {
        return (
            <Card component={'a'} href={'/movie/' + item.id} className={'lehmoflix-video-card'}
                  sx={{display: 'flex', flexDirection: 'column', textDecoration: 'none', minWidth: 100, height: '100%'}}
                  onClick={openVideo}>
                <Box sx={{position: 'relative'}}>
                    <LoadingCardImage src={item.background_image} sx={{height: 200}}/>
                    <Typography variant={'caption'} sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        px: 0.5,
                        bgcolor: 'background.duration',
                    }}
                                color={'inherit'}>{getFormattedDuration()}</Typography>
                </Box>

                <CardContent sx={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: 2, pt: 2}}>
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                        <Button disabled variant={'contained'} sx={{py: 0.25, mr: 1}}>
                            <Typography
                                variant={'caption'}
                                fontWeight={'bold'}
                                color={'text.primary'}
                                sx={{width: 'auto'}}
                            >{item.min_age}</Typography>
                        </Button>
                        <Button disabled variant={'outlined'} sx={{py: 0.25, mr: 1}}>
                            <Typography variant={'caption'} fontWeight={'bold'} sx={{width: 'auto'}}
                                        color={'inherit'}>{moment(item.release_date).format('YYYY')}</Typography>
                        </Button>
                        {item.status === 'premium' && (
                            <Button disabled variant={'outlined'} sx={{py: 0.25, mr: 1}}>
                                <Typography variant={'caption'} fontWeight={'bold'} sx={{width: 'auto'}}
                                            color={'warning.main'}>Premium</Typography>
                            </Button>
                        )}
                    </Box>
                    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <Typography variant="inherit" color={'text.primary'} fontWeight={'bold'}>
                            {item.title}
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'}>{item.stream_count + ' Aufruf(e) • ' + moment(item.release_date).format('DD.MM.YYYY')}</Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    };

    return useMemo(renderBackgroundCard, [item]);
};

export default VideoItem;
