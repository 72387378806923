import React, {useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import {useMovieService} from "../../services/movie";
import PlayerItem from "../Players/item";

const VideoPlayers = ({videoId}) => {
    const movieService = useMovieService();
    const [players, setPlayers] = useState([]);

    const onSuccess = (response) => {
        setPlayers(response);
    };

    useEffect(() => {
        if (videoId) {
            movieService.getPlayerRolesByVideoId(videoId).then(onSuccess);
        }
    }, [videoId])

    return useMemo(() => {
        if (players.length > 0) {
            return (
                <Box sx={{py: 1}}>
                    {/*<Typography variant={'h6'}>Schauspieler</Typography>*/}
                    <Box sx={{position: 'relative', flex: 1, pt: 1}}>
                        <div className={'scroll-overlay right'} />
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            overflowX: 'scroll',
                            flexWrap: 'no-wrap',
                            py: 1,
                            my: 0.25,
                        }} className={'moz-scrollbar'}>
                            {players.map((player, index) => (
                                <PlayerItem player={player} key={'player-' + player.id + '-' + index}/>
                            ))}
                        </Box>
                    </Box>
                </Box>
            );
        }
    }, [players])
};

export default VideoPlayers;
