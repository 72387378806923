import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useMovieService} from "../../services/movie";
import VideoItem from "../Video/item";
import {Box, Container, Grid, Typography} from "@mui/material";
import GroupFilter from "../Video/group-filter";
import {useAuthenticationApi} from "../../services/authentication";

const HomePage = ({handleLoadStarted, handleLoadEnded, scrollRef}) => {
    let lastCall = -1;
    const [movies, setMovies] = useState([]);

    const [loadedGroup, setLoadedGroup] = useState(null);
    const [loadedOffset, setLoadedOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    const authService = useAuthenticationApi();
    const movieService = useMovieService();

    const load = (params = {offset: 0, group: null}) => {
        const loadEnd = ({data}) => {
            if (data?.movies && data.movies.length > 0) {
                if (params.offset === 0) {
                    setLoadedGroup(params?.group);
                    setMovies(data.movies);
                } else {
                    setMovies([...movies, ...data.movies]);
                }
                setLoadedOffset(params?.offset + 15);
            }

            if (!data.movies || (data.movies.length === 0 && params.offset === 0)) {
                setMovies([]);
            }

            setLoading(false);
            handleLoadEnded();
        };

        if (params.offset === 0) {
            lastCall = -1;
        }

        if (lastCall < params.offset) {
            lastCall = params.offset;
            handleLoadStarted();
            setLoading(true);
            movieService
                .list(params)
                .then(loadEnd)
                .catch(loadEnd);
        }
    };

    const scrollHandler = useCallback((event) => {
        const containerHeight = event.currentTarget.clientHeight;
        const scrollHeight = event.currentTarget.scrollHeight;
        const scrollTop = event.currentTarget.scrollTop;

        if ((((scrollTop + containerHeight) / scrollHeight) * 100) > 80 && !loading && loadedOffset) {
            load({offset: loadedOffset, group: loadedGroup});
        }
    }, [loadedOffset, loading, loadedGroup]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.onscroll = scrollHandler;
        }

        return () => {
            if (scrollRef?.current) {
                scrollRef.current.onscroll = null;
            }
        }
    }, [movies, scrollRef]);

    useEffect(() => {
        load();
        document.title = 'LEHMOFLIX';
    }, [authService])

    return (
        <Container maxWidth={'xl'} sx={{p: 2}}>
            <GroupFilter center={true} filter={load.bind(this)}/>
            <Grid container spacing={2} sx={{py: 1}}>
                {movies.length > 0 ? movies.map((item, index) => (
                    <Grid key={'video-' + index} item zeroMinWidth={true} xs={12} xl={4} md={6} sm={12}>
                        <VideoItem item={item} inGrid={true}/>
                    </Grid>
                )) : (
                    <Box
                        sx={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300}}>
                        <Typography variant={'h6'}>Hier gibt es leider noch keine Videos</Typography>
                    </Box>
                )}
            </Grid>
        </Container>
    );
};

export default HomePage;
