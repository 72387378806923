import React, {useEffect, useRef, useState} from "react";
import {Alert, AlertTitle, Box, Container, Typography} from "@mui/material";
import VideoPlayer from "../Player";
import {useAuthenticationApi} from "../../services/authentication";
import {useParams} from "react-router-dom";
import {useTvService} from "../../services/tv";
import {usePrevious} from "../../hooks/previous";
import {CellTower} from "@mui/icons-material";

const ChannelDetailPage = ({handleLoadStarted, handleLoadEnded}) => {
    const {groupId} = useParams();
    const prevGroupId = usePrevious(groupId);

    const auth = useAuthenticationApi();
    const tvService = useTvService();

    const playerRef = useRef(null);

    const [channel, setChannel] = useState({});

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    const loadData = () => {
        const onSuccess = (response) => {
            setChannel(response);
            handleLoadEnded();
        };

        if (groupId !== prevGroupId) {
            handleLoadStarted();
            tvService.getChannelById(groupId).then(onSuccess);
        }
    };

    useEffect(() => {
        if (groupId !== channel.id) {
            loadData();
        }
    }, [groupId]);

    return (
        <Container maxWidth={'lg'}>
            <Box sx={{py: 4}}>
                <VideoPlayer data={channel} type={'channel'} user={auth.user} onReady={handlePlayerReady} />
                <Alert variant={'outlined'} severity="info" sx={{borderRadius: 0, mt: 1}}>
                    Dieser Livestream repräsentiert die Videos aus der Gruppe: {channel.name}
                </Alert>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <CellTower sx={{fontSize: 40, mr: 1}} />
                    <Typography variant={'h4'} sx={{flex: 1, py: 1}}>{channel.name}</Typography>
                </Box>
            </Box>
        </Container>
    )
};

export default ChannelDetailPage;
