import React, {useEffect, useState} from "react";
import {Badge, Box, Container, Grid, IconButton, Paper, styled, Tab, Tabs, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useUserService} from "../../services/user";
import LoadingCardImage from "../LoadingCardImage";
import {AlternateEmail, Edit, Lock} from "@mui/icons-material";
import {useAuthenticationApi} from "../../services/authentication";
import VideoListItem from "../Video/list-item";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import moment from "moment";


const UserProfilePage = ({handleLoadStarted, handleLoadEnded}) => {
    const navigate = useNavigate();
    const {userId} = useParams();
    const authService = useAuthenticationApi();
    const userService = useUserService();

    const [data, setData] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const isOwn = Boolean(userId === authService.user.id);

    const onLoadEnd = (response) => {
        setData(response);
        document.title = 'LEHMOFLIX | ' + response.username;
        handleLoadEnded();
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (userId) {
            handleLoadStarted();
            userService.getUserById(userId).then(onLoadEnd)
        }

        return () => {
            handleLoadEnded();
        }
    }, [userId])

    return (
        <Container maxWidth={'xl'} sx={{p: 4}}>
            <Grid container spacing={4}>
                <Grid item xl={3} md={4} sm={12} sx={{}}>
                    <Paper sx={{pb: 2, position: 'sticky', top: '96px', borderRadius: 2}}>
                        <LoadingCardImage src={data?.image ?? '/user_placeholder.jpg'}
                                          sx={{width: '100%', borderRadius: 1}}/>
                        <Box
                            sx={{
                                px: 2,
                                pt: 2,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between'
                            }}>
                            {data?.full_name ? (
                                <Box sx={{width: '100%'}}>
                                    <Typography variant={'h5'}
                                    >{data?.full_name}</Typography>
                                    <Box sx={{display: 'flex', alignItems: 'center', py: 0.5}}>
                                        <AlternateEmail sx={{fontSize: 14, color: 'secondary.main', mr: 0.5}}/>
                                        <Typography variant={'overline'}
                                                    color={'secondary'}>{data?.username}</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography variant={'h5'}>{data?.username}</Typography>
                            )}
                            {isOwn && (
                                <IconButton color={'inherit'} title={'Dein Profil bearbeiten'} onClick={() => navigate('/settings')}>
                                    <Edit />
                                </IconButton>
                            )}
                        </Box>
                        {isOwn && (
                            <Box sx={{px: 2, pb: 2, display: 'flex', alignItems: 'center'}}>
                                <Lock sx={{mr: 0.5, color: 'text.secondary', fontSize: 14}}
                                      titleAccess={'Nur du siehst deine E-Mail Adresse'}/>
                                <Typography variant={'body2'} sx={{color: 'text.secondary'}}
                                            title={'Nur du siehst deine E-Mail Adresse'}>{authService.user.email}</Typography>
                            </Box>
                        )}
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2}}>
                            <Typography variant={'overline'} color={'text.secondary'}>Angesehene Videos</Typography>
                            <Typography variant={'overline'} fontWeight={'bold'}
                                        color={'secondary'}>{data?.watched_count}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2}}>
                            <Typography variant={'overline'} color={'text.secondary'}>Beteiligt</Typography>
                            <Typography variant={'overline'} fontWeight={'bold'}
                                        color={'secondary'}>{data?.player_roles?.length}</Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2}}>
                            <Typography variant={'overline'} color={'text.secondary'}>Kommentare</Typography>
                            <Typography variant={'overline'} fontWeight={'bold'}
                                        color={'secondary'}>{data?.comments?.length}</Typography>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xl={9} md={8} sm={12}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 1}}>
                        <Tabs value={selectedTab} variant={'standard'} onChange={handleTabChange} sx={{
                            bgcolor: 'background.drawer',
                            borderRadius: 1,
                        }}>
                            <Tab label={'Beteiligt an'}/>
                            <Tab label={'Kommentare'}/>
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        {data?.player_roles?.map((role, index) => {
                            return (
                                <VideoListItem key={'movie-' + index} item={role.movie} searchVersion={true}/>
                            )
                        })}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <Timeline position="alternate">
                            {data?.comments?.map((comment, index) => {
                                return (
                                    <TimelineItem key={'comment-' + index}>
                                        <TimelineSeparator>
                                            <TimelineDot color="primary"/>
                                            {(index + 1) < data?.comments.length && (
                                                <TimelineConnector/>
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant={'body1'}>{comment.body}</Typography>
                                            <Typography
                                                variant={'caption'}>{moment(comment.created_at).format('DD.MM.YYYY')}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                )
                            })}
                        </Timeline>
                    </TabPanel>
                </Grid>
            </Grid>
        </Container>
    );
};

const OnlineIndicator = styled(Badge)(({theme, online = false}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: online ? '#44b700' : '#666666',
        color: online ? '#44b700' : '#666666',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        width: 10,
        height: 10,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: online ? 'ripple 1.2s infinite ease-in-out' : '',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default UserProfilePage;
