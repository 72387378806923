import React, {useCallback, useEffect, useState, useMemo} from "react";
import {Box, Button} from "@mui/material";
import {useMovieService} from "../../services/movie";
import VideoListItem from "./list-item";
import {useAuthenticationApi} from "../../services/authentication";
import GroupFilter from "./group-filter";

const defaultParams = {offset: 0, group: null};

const RecentList = ({disabledItem}) => {
    const authService = useAuthenticationApi();
    const movieService = useMovieService();

    const user = authService.user;

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [canLoadMore, setCanLoadMore] = useState(false);

    const [lastParams, setLastParams] = useState(defaultParams);

    const onSuccess = ({data, inputParams}) => {
        if (inputParams?.group !== lastParams?.group || inputParams?.offset === 0) {
            setItems(data.movies);
        } else {
            setItems([...items, ...data.movies]);
        }
        if (data.movies.length === 0) {
            setCanLoadMore(false);
        } else {
            setCanLoadMore(true);
        }
        setLastParams(inputParams);
        setLoading(false);
    };

    const loadData = (params = defaultParams) => {
        setLoading(true);
        movieService.list(params).then(onSuccess);

        return params;
    };

    const loadMore = useCallback(() => {
        loadData({...lastParams, ...{offset: lastParams.offset + 15}});
    }, [lastParams]);

    const init = () => {
        if (items.length === 0 && !loading) {
            loadData();
        }
    };

    useEffect(init);

    return useMemo(() => {
        return (
            <Box className={'lehmoflix-recent'} sx={{flex: 1, overflowX: 'visible'}}>
                <GroupFilter filter={loadData.bind(this)} />
                <Box sx={{minWidth: 400}}>
                    {items.map((item, index) => {
                        if (disabledItem && disabledItem === item.id) {
                            return null;
                        } else {
                            return (
                                <VideoListItem key={'video-list-item-' + item.id + '-' + index} item={item}/>
                            )
                        }
                    })}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
                    {canLoadMore && (
                        <Button variant={'text'} color={'secondary'} onClick={loadMore}>Mehr laden</Button>
                    )}
                </Box>
            </Box>
        );
    }, [disabledItem, user, items, canLoadMore]);
};

export default RecentList;
