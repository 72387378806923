import React, {createContext, useContext} from "react";
import {useAuthenticationApi} from "./authentication";

const LehmoflixApi = require('../api');

export const useTvService = () => {
    const authentication = useAuthenticationApi();
    const api = new LehmoflixApi.TVApi(authentication.getApiClient());

    const getChannels = () => {
        return new Promise((resolve, reject) => {
            api.getChannels((error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            })
        });
    };

    const getChannelById = (id) => {
        return new Promise((resolve, reject) => {
            api.getChannelById(id,(error, data, response) => {
                if (!error) {
                    resolve(response?.body);
                } else {
                    reject();
                }
            })
        });
    };

    return useContext(createContext({getChannels, getChannelById}));
}
