import React, {Component, createContext, useContext} from "react";
import ApiHelper from "../api/helper";
import moment from "moment";

const LehmoflixApi = require('../api');

const defaultUser = null;

export class AuthProvider extends Component {
    _api;

    state = {
        user: defaultUser,
    }

    constructor(props) {
        super(props);
        this._api = new LehmoflixApi.AuthorizationApi(new LehmoflixApi.ApiClient());
        this.state.user = JSON.parse(localStorage.getItem('user'));
        console.log('user loaded', this.state.user);
    }

    isAuthenticated = () => {
        if (this.state.user) {
            const timeStamp = parseInt(moment().format('x'));
            if (this.state.user.token_end <= timeStamp) {
                console.log('must refresh token');
                this.refreshToken();
            }
            return true;
        }
        return false;
    };

    getUser = () => {
        return this.state.user;
    };

    saveUser = (newUser) => {
        try {
            const tempUser = {...this.state.user, ...newUser};
            localStorage.setItem('user', JSON.stringify(tempUser));
            this.setState({user: tempUser})
            return tempUser;
        } catch (e) {
            return false;
        }
    };

    login = (formData) => {
        const self = this;
        return new Promise((resolve, reject) => {
            this._api.login(formData, (error, data, response) => {
                if (!error) {
                    const timeStamp = parseInt(moment().add(3600, 's').format('x'));
                    self.saveUser({...response.body, ...{token_end: timeStamp}});
                    resolve(response.body);
                } else {
                    reject();
                }
            })
        });
    };

    refreshToken = () => {
        const self = this;
        return new Promise((resolve, reject) => {
            const refresh_token = ApiHelper.getRefreshToken();
            this._api.refreshToken({refresh_token}, (error, data, response) => {
                if (!error) {
                    const timeStamp = parseInt(moment().add(3600, 's').format('x'));
                    self.saveUser({...response.body, ...{token_end: timeStamp}});
                    resolve(response.body);
                } else {
                    reject();
                }
            })
        });
    };

    logout = () => {
        try {
            this.setState({
                user: defaultUser,
            });
            localStorage.removeItem('user');
        } catch (e) {
        }
    };

    getApiClient = () => {
        return ApiHelper.getApiClient(this.state.user);
    };

    render() {
        const {children} = this.props;
        const {user} = this.state;
        const {isAuthenticated, logout, login, refreshToken, saveUser, getUser, getApiClient} = this;

        return (
            <AuthContext.Provider
                value={{user, isAuthenticated, login, logout, getUser, saveUser, refreshToken, getApiClient}}>
                {children}
            </AuthContext.Provider>
        )
    }
}

export const AuthContext = createContext({
    user: defaultUser,
    isAuthenticated: () => {
    },
    login: (data) => {
    },
    logout: () => {
    },
    getUser: () => {
    },
    saveUser: (newUser) => {
    },
    refreshToken: () => {
    },
    getApiClient: () => {
    },
});
AuthContext.displayName = 'Authentication';

export const useAuthenticationApi = () => {
    return useContext(AuthContext);
};
